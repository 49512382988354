import React, { useEffect, useRef, useState } from "react";
import PDFGenerator from "./PDFGenerator";
import QRCode from "react-qr-code";
import ReactToPrint from "react-to-print"; // Import ReactToPrint

export const Certificate = ({
  employee,
  selectedEmployee,
  setIsCertificate,
}) => {
  const url = "https://www.roadslink.in/certification";
  let siteUrl = "https://www.roadslink.in";

  const certificateRef = useRef(null);
  const [isTextLong, setIsTextLong] = useState(false);

  useEffect(() => {
    // Check if text is long
    const textLength = selectedEmployee.companyName.length;
    setIsTextLong(textLength > 35);
  }, [selectedEmployee.companyName]);

  console.log(selectedEmployee);
  const date = selectedEmployee?.date ? new Date(selectedEmployee.date) : null;
  const formattedDate = date
    ? date.toLocaleString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      })
    : null;

  function toFixed(num) {
    return Math.round(num);
  }

  const qrCodeUrl = `${siteUrl}/certification?registrationId=${selectedEmployee.registrationId}`;

  return (
    <>
      <div className="header-btn">
        <div className="header-right">
          <button
            style={{ marginRight: "12px", padding:'0 20px', margin:'0', height:'40px'}}
            className="muted-button"
            onClick={() => setIsCertificate(false)}
          >
            Back
          </button>

          {/* Wrap PDFGenerator inside ReactToPrint */}
          <ReactToPrint
            trigger={() => <button style={{ marginRight: "12px", padding:'0 20px', margin:'0', height:'40px'}}>Print</button>}
            content={() => certificateRef.current}
          />
        </div>
      </div>
      

<div id="certificate-content" ref={certificateRef}>
<div className="container-fluid" >

    {/* border */}
    <div className="row mb-3">
      <div className="col-sm-6 m-0 p-0">
         <div className="black-header"></div>
      </div>
      <div className="col-sm-6 m-0 p-0">
      <div className="orange-header"></div>
      </div>
    </div>
    
    {/* logo */}

    <div className="row">
    <div className="col-sm-6">
         <div className="right-logo">
         <img
                src={require("../../assets/file.png")}
                className="logo d-block "
                alt=""
                style={{height:'40px', width:'200px'}}
              />
         </div>
      </div>
      <div className="col-sm-6 text-right">
      <div className="left-logo">
      <img
                src={require("../../assets/rta-logo.png")}
                className="logo"
                alt=""
                style={{  width:'340px', }}

              />
      </div>
      </div>
    </div>

    <div className="row">
      <div className="col-sm-12">
         <div className="category">
             <span id="cat-head">Category: <span>{selectedEmployee.category}</span></span> 
         </div>
      </div>
    </div>

    <div className="row mt-5">
      <div className="col-sm-12">
         <div className="certificate-content">
             <div className="title">
                <h4>This certificate is proudly presented to</h4>                 
                <h4><b class="uppercase">{selectedEmployee.name}</b></h4>   
                <h4>for the successful completion of the following courses :</h4>              
             </div>
             <div className="test mt-3">
                 <div className="RTA-heading">
                     <h4>RTA Psychometric Tests</h4>
                 </div>
                 <div className="RTA-heading">
                 <h4>RTA English Test</h4>
                 </div>
             </div>
             
             <div id="numbers">
                  <div id="rta-number">
                  <p className="p-0 m-0 pt-2"> {selectedEmployee.rtaPsychometricTest} out of 100</p>
                  </div>
                  <div id="rta-english-number">
                <p className="p-2 m-0">{selectedEmployee.rtaEnglishTest} out of 150</p> 
                  </div>
             </div>

             <div id="percentage" className="p-0 m-0">
                  <div id="rta-percentage">
                  <p className="p-0 m-0">({toFixed((selectedEmployee.rtaPsychometricTest / 100) * 100)}
                  %)</p>
                  </div>
                  <div id="rta-english-percentage">
                <p className="p-0 m-0"> ({toFixed((selectedEmployee.rtaEnglishTest / 150) * 100)}%)</p> 
                  </div>
             </div>
         </div>
      </div>
    </div>

    <div className="row my-4 mb-2">
      <div className="col-sm-3">
         <div className="stamp-logo">
           <img
                    style={{ height:'200px',}}
                    src={require("../../assets/stamp.jpeg")}
                    className="stamp-qrcode"
                    alt="signature"
                    
                  />
         </div>
      </div>
      <div className="col-sm-6 text-center">
               
               <div className="verification-section">
                 <p>Verified Certificate</p>
                 <p>
                   Issued by: <a href={siteUrl}>www.roadslink.in</a>
                 </p>
                 <p style={{ display: "inline-block" }}>
                   Please Verify at:{" "}
                   <a
                     href={`${url}?registrationId=${selectedEmployee.registrationId}&passportNumber=${selectedEmployee.passportNumber}`}
                   >
                     www.roadslink.in/certification
                   </a>
                   <br />
                   using passport number.
                 </p>
               </div>
      </div>
      <div className="col-sm-3 text-center">
        <div className="qr-code">
        <QRCode value={qrCodeUrl} style={{ height: "160px", marginRight:'130px'}} />
        </div>
      </div>
    </div>

     <div className="footer-certificate mb-4">
        <div className="row">
          <div className="col-sm-3 px-4">
            <img
                    style={{ width: '230px',marginBottom:'7px' }}
                    src={require("../../assets/signature.jpeg")}
                    className="stamp-qrcode d-block"
                    alt="signature"

                    
                  />
                  <div className="p-0 m-0" style={{height:'2px', background:'black'}}></div>
                  <p className="m-0 p-0" ><b>Authorized signature and stamp</b></p>
                
          </div>
          <div className="col-sm-2">
         <div className="px-4">
         <p className="text text-center"> {selectedEmployee.certificateCode}</p>
          <div className="p-0 m-0" style={{height:'2px', background:'black'}}></div>

          <p><b>Certificate Code</b></p>
         </div>
          </div>
          <div className="col-sm-2">
           <div className="px-4">
           <p className="text text-center"> {selectedEmployee.studentId}</p>
          <div className="p-0 m-0" style={{height:'2px', background:'black'}}></div>

          <p><b>Student Id</b></p>
           </div>
          </div>
          <div className="col-sm-3" id="text-setter">
          <div className=" company-info">
          <p className={`text-company text-center ${isTextLong ? 'long-text' : ''}`}>{selectedEmployee.companyName}</p>
          <div className="separator"></div>
          <p><b>Name of the company</b></p>
        </div>

          </div>
          <div className="col-sm-2">
           <div className="px-4">
           <p className="text text-center"> {formattedDate}</p>
          <div className="p-0 m-0" style={{height:'2px', background:'black'}}></div>

          <p><b>Date</b></p>
           </div>
          </div>
        </div>
     </div>

      {/* border */}
    <div className="row ">
      
      <div className="col-sm-6 m-0 p-0">
      <div className="orange-header"></div>
      </div>
      <div className="col-sm-6 m-0 p-0">
         <div className="black-header"></div>
      </div>
    </div>

 </div>
</div>
 

    </>
  );
};
